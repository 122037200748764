import {Routes} from '@angular/router';

export const MAIN_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/app/campaigns'
  },
  {
    path: 'app',
    loadChildren: () => import('./app/app.routes').then(mod => mod.APP_ROUTES),
    data: {preload: true}
  },
  {
    path: '**',
    redirectTo: '/app/campaigns'
  }
];
