import {Component} from '@angular/core';
import {AuthenticationService} from '../authentication.service';
import {AsyncPipe, Location, NgClass, NgIf, NgOptimizedImage, UpperCasePipe} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AppConfigService} from "../../shared/services/app-config.service";
import {CpIconChevronDownComponent} from "../../shared/cp-svg-icons/cp-icon-chevron-down.component";
import {catchError, EMPTY, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ClickOutsideDirective} from "../../shared/directives/click-outside.directive";
import {CpIconLogoutComponent} from "../../shared/cp-svg-icons/cp-icon-logout.component";

@Component({
  selector: 'cp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    RouterLinkActive,
    NgIf,
    AsyncPipe,
    NgOptimizedImage,
    CpIconChevronDownComponent,
    ClickOutsideDirective,
    UpperCasePipe,
    CpIconLogoutComponent
  ]
})
export class HeaderComponent {

  username$ = this._auth.getUsername();
  authenticated$ = this._auth.getIsAuthenticated();
  anonymous$ = this._auth.getIsAnonymous();
  logoutUrl$ = this._auth.getLogoutUrl();

  isBannerDisplayed = false;
  isUserMenuDisplayed = false;
  nameInitial = '';

  constructor(
    protected readonly appConfig: AppConfigService,
    private readonly _auth: AuthenticationService,
    private readonly _location: Location
  ) {
    _auth.getSession();

    this.username$
      .pipe(
        tap(result => {
          if (result != null) {
            const names = result.split(' ');
            this.nameInitial = names.length > 1
              ? names[0].substring(0, 1) + names[1].substring(0, 1)
              : result.substring(0, 2);
          }
        }),
        catchError(err => {
          console.log(err);
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.checkBanner();
  }

  onToggleUserMenu() {
    this.isUserMenuDisplayed = !this.isUserMenuDisplayed;
  }

  onHideUserMenu() {
    this.isUserMenuDisplayed = false;
  }

  private checkBanner() {
    const path = this._location.path().split('/');
    if (path.length > 2) {
      const lastSegment = path[path.length - 1];
      const prevSegment = path[path.length - 2];
      this.isBannerDisplayed = prevSegment === 'campaign' && lastSegment?.length > 0;
    }
  }

}
