<div>
  @if (isBannerDisplayed) {
    <div class="cp-confidential-banner">
      This page is confidential. Do not share with anyone that’s unauthorized to view this.
    </div>
  }

  <header class="cp-header">
    <div>
      <img ngSrc="assets/images/logo.svg" alt="Client Portal" height="23" width="127" priority>
    </div>

    <div class="user-menu-container"
         (click)="onToggleUserMenu()"
         (clickOutside)="onHideUserMenu()">
      @if (authenticated$ | async) {
        <div class="user-avatar">
          <span class="flex-center">{{ nameInitial | uppercase }}</span>
        </div>

        <cp-icon-chevron-down class="icon-chevron-down"></cp-icon-chevron-down>

        <div class="user-menu" [hidden]="!isUserMenuDisplayed">
          <a href="{{logoutUrl$ | async}}" (click)="onHideUserMenu()">
            <cp-icon-logout class="icon-logout"></cp-icon-logout>
            Logout
          </a>
        </div>
      }
    </div>
  </header>
</div>



