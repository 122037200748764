import {CpIconBaseComponent} from "./cp-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'cp-icon-exclamation-triangle',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="cp-svg-icon cp-svg-exclamation-triangle"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4449 1.10878C8.0183 0.393 6.9817 0.393 6.55509 1.10878L0.161178 11.8368C-0.275824 12.5701 0.252503 13.5 1.10608 13.5H13.8939C14.7475 13.5 15.2758 12.5701 14.8388 11.8368L8.4449 1.10878ZM7.4141 1.62075C7.45288 1.55568 7.54712 1.55568 7.5859 1.62075L13.9798 12.3488C14.0196 12.4155 13.9715 12.5 13.8939 12.5H1.10608C1.02849 12.5 0.980454 12.4155 1.02018 12.3488L7.4141 1.62075ZM6.8269 4.98613C6.81221 4.60424 7.11783 4.28665 7.5 4.28665C7.88217 4.28665 8.18778 4.60424 8.1731 4.98613L8.01921 8.98703C8.00848 9.26602 7.7792 9.48666 7.5 9.48666C7.2208 9.48666 6.99151 9.26602 6.98078 8.98703L6.8269 4.98613ZM8.24989 10.976C8.24989 11.3902 7.9141 11.726 7.49989 11.726C7.08567 11.726 6.74989 11.3902 6.74989 10.976C6.74989 10.5618 7.08567 10.226 7.49989 10.226C7.9141 10.226 8.24989 10.5618 8.24989 10.976Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class CpIconExclamationTriangle extends CpIconBaseComponent {
}
