import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfHeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // currently for development purposes token is taken from Postman all the time
    // const headers = new HttpHeaders({
    //   'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IkVCRUZDRDRBRTA3NUNBRjU5MUYzOUU1MjdDNzEzQ0UzOUZEMkMwNDFSUzI1NiIsIng1dCI6IjYtX05TdUIxeXZXUjg1NVNmSEU4NDVfU3dFRSIsInR5cCI6ImF0K2p3dCJ9.eyJpc3MiOiJodHRwczovL2FwcHNlci1sYy1pZGVudGl0eS1kZXYuYXp1cmV3ZWJzaXRlcy5uZXQiLCJuYmYiOjE3MTg2MTA5NDEsImlhdCI6MTcxODYxMDk0MSwiZXhwIjozODY2MDk0NTg4LCJhdWQiOlsiY3VzdG9tZXJwb3J0YWxhcGkiLCJodHRwczovL2FwcHNlci1sYy1pZGVudGl0eS1kZXYuYXp1cmV3ZWJzaXRlcy5uZXQvcmVzb3VyY2VzIl0sInNjb3BlIjpbIm9wZW5pZCIsImN1c3RvbWVycG9ydGFsYXBpLmZ1bGxhY2Nlc3MiLCJyb2xlcyIsInRlbmFuY3kiLCJhZ2VuY3kiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJjdXN0b21lcnBvcnRhbC5wb3N0bWFuLmNsaWVudCIsInN1YiI6ImYxOWIzMzBjLTNmNzQtNDdjNS1hM2NiLTk3NTc0ODEwNWUwMyIsImF1dGhfdGltZSI6MTcxODI4MzA4OCwiaWRwIjoibG9jYWwiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaWQiOiIyMjQwMDBGODk0NDYwQkJDMzA0OUI4NUNDQzVDNUJFQiIsImp0aSI6IjI3Q0VCQ0Y3NkY1NjEwNzEzNEI0OTU3MTI4OTZDMDE1In0.O0ch6qwxkx7NsOnwK85wzp9sSotIVUIY-XlUqUlVUCvFLqCMarLdzHdaTEVg_LydlJIk6rN1_wFRi0RsmpsHEeHmVEIi0R3zjZ5fNu436EAWTkBQUlpC5ojGktzoS_isV02aOjcpS2cto9Khrlgcl1933zIhuqshL6KEu1YNt1RZk7Y8_1ALnXeSNx1tDsAfiV0ibUBUaVc2FN49MMvWnHZbaNgCjpun1s0DP1GnCen6_1P6m2AteEi8xCSErtuSoJ4iwSILUXrf1DIhdiJZzGhBks0lIW43edzravL2U1VJB0vzPagWT53Mo5hIX2ATh_Lc3oJ3D_rBRwQOfCEEaw',
    // });
    // if (!request.headers.has("X-CSRF")) {
    //   headers.append("X-CSRF", "1");
    //   request = request.clone({
    //     headers
    //   });
    // }

    if (!request.headers.has("X-CSRF")) {
      request = request.clone({
        headers: request.headers.set("X-CSRF", "1"),
      });
    }

    return next.handle(request);
  }
}


