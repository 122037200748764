import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {catchError, filter, lastValueFrom, map, Observable, of, shareReplay} from 'rxjs';
import {API_REMOTE_SERVICE_URL} from "../main.config";

const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private session$: Observable<Session> | null = null
  private readonly _baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_REMOTE_SERVICE_URL) baseUrl: string
  ) {
    this._baseUrl = baseUrl ?? '';
  }

  public getSession(ignoreCache: boolean = false) {
    if (!this.session$ || ignoreCache) {
      const url = `${this._baseUrl}/user`

      this.session$ = this.http.get<Session>(url)
        .pipe(
          catchError(err => {
            return of(ANONYMOUS);
          }),
          shareReplay(CACHE_SIZE)
        );
    }
    return this.session$;
  }

  public getIsAuthenticated(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAuthenticated)
    );
  }

  public getIsAnonymous(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAnonymous)
    );
  }

  public getUsername(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'name')?.value)
    );
  }

  public getLogoutUrl(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'bff:logout_url')?.value)
    );
  }

  public isSuperAdmin(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(IsSuperAdmin)
    );
  }

  async isLoggedIn(ignoreCache: boolean = false): Promise<boolean> {
    return await lastValueFrom(this.getIsAuthenticated(ignoreCache));
  }
}

export interface Claim {
  type: string;
  value: string;
}

export type Session = Claim[] | null;

const UserIsAuthenticated = (s: Session): s is Claim[] => {
  return s !== null;
}

const UserIsAnonymous = (s: Session): s is null => {
  return s === null;
}

const IsSuperAdmin = (s: Session): s is null => {
  return s?.find(c => c.type === 'role')?.value.toLowerCase() === 'super-admin';
}


